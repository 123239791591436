import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import Axios from "axios";
import $ from 'jquery';

const Activity = () => {
    const id = useParams().id;
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            recentPosts: 'Recent Posts',
        },
        ar: {
            recentPosts: 'المنشورات الأخيرة',
        },
        krd: {
            recentPosts: 'دوایین چالاکییەکان',
        }
    }
    const [activities, setActivities] = useState([]);
    const getActivities = async () => {
        const res = await Axios.get(`/activities-limit?lang=${lang}`);
        setActivities(res.data);
    }

    const [activity, setActivity] = useState({});
    const getActivity = async () => {
        const res = await Axios.get(`/activities/${id}?lang=${lang}`);
        setActivity(res.data[0]);
    }
    const [imageActivity, setImageActivity] = useState([]);
    const getImageActivity = async () => {
        const res = await Axios.get(`/image/activitie/${id}`);
        setImageActivity(res.data);
    }
    useEffect(() => {
        getActivities();
        getActivity();
        getImageActivity();
        if (lang !== "en") {
            $(".blog-single").css("direction", "rtl");
            $('.sidebar-area').css("direction", "rtl");
        }

    }, [])

    useEffect(() => {
        document.title = activity.title;
    }, [activity])

    return (
        <section className="as-blog-wrapper blog-details space-top space-extra-bottom">
            <div className="container">
                <div className="row">
                    <div className="col-xxl-8 col-lg-7">
                        <div className="as-blog blog-single">
                            <div className="blog-img">
                                <img src={`../../${activity.img}`} alt={activity.title} />
                            </div>
                            <div className="blog-content">
                                <div className="blog-meta">
                                    <a href={`/activity/${activity.id}`}><i className="fa-solid fa-calendar-days" />{new Date(activity.created).toLocaleDateString()}</a>
                                </div>
                                <h2 className="blog-title">{activity.title}</h2>
                                <p className="blog-text">
                                    <div dangerouslySetInnerHTML={{ __html: activity.content }} />
                                </p>
                            </div>
                        </div>
                        <div className="as-comments-wrap">
                            {imageActivity && imageActivity.map((img, index) => (
                                <div className="blog-img" key={index}>
                                    <img src={`../../${img.src}`} alt={activity.title} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-xxl-4 col-lg-5">
                        <aside className="sidebar-area">
                            <div className="widget">
                                <h3 className="widget_title">{keyword[lang].recentPosts}</h3>
                                <div className="recent-post-wrap">
                                    {activities.map((item, index) => (
                                        <div className="recent-post" key={index}>
                                            <div className="media-img"><Link to={`/activity/${item.id}`}><img src={`../../${item.img}`} alt={item.title} /></Link></div>
                                            <div className="media-body">
                                                <h4 className="post-title"><Link className="text-inherit" to={`/activity/${item.id}`}>{item.title}</Link></h4>
                                                <div className="recent-post-meta"><Link to={`/activity/${item.id}`}><i className="fas fa-calendar-days" />{new Date(item.created).toLocaleDateString()}</Link></div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Activity;