import { useState } from "react";
import $, { get } from "jquery";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { toast } from "react-toastify";
const UpdateSocial = () => {

    const logout = () => {
        Axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }

    const [social, setSocial] = useState([]);
    const getSocial = async () => {
        const res = await Axios.get("/get-social");
        setSocial(res.data[0]);
    }

    useState(() => {
        getSocial();
        $("body").addClass("sidebar-dark");
    }, []);

    return (
        <div className="main-wrapper">
            < Sidebar />
            <div className="page-wrapper">
                <nav className="navbar">
                    <a href="#0" className="sidebar-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </a>
                    <div className="navbar-content">

                        <ul className="navbar-nav">
                            <li className="nav-item" onClick={logout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <div className="row">
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-title mb-5">Update Social Link</h6>
                                    <form className="forms-sample" onSubmit={(e) => {
                                        e.preventDefault();
                                        const data = {
                                            fb: e.target.fb.value,
                                            twitter: e.target.twitter.value,
                                            ig: e.target.ig.value,
                                        };
                                        Axios.post("/update-social", data).then((res) => {
                                            toast.success("Social Link Updated");
                                        });
                                    }}>
                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">Facebook</label>
                                            <input className="form-control" type="text" name="fb" placeholder="https://www.facebook.com/" defaultValue={social.fb} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">Instagram</label>
                                            <input className="form-control" type="text" name="ig" placeholder="https://www.instagram.com/" defaultValue={social.ig} />
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="formFile" className="form-label">Twitter</label>
                                            <input className="form-control" type="text" name="twitter" placeholder="https://twitter.com/" defaultValue={social.twitter} />
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary" type="submit">Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.easy-soft.co/" target="_blank">KCU</a>.</p>
                </footer>
            </div>
        </div>
    );
}

export default UpdateSocial;