/* eslint-disable */
import { Link } from "react-router-dom";
import $ from 'jquery';
import { useState, useEffect } from "react";


const Footer = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            home: 'Home',
            about: 'About Us',
            contact: 'Contact Us',
            activites: 'Activites',
            branches: 'Branches',
            members: 'Members',
            footer1: 'High Board',
            footer2: 'Kurdistan Contractors Union',
        },
        ar: {
            home: 'الرئيسية',
            about: 'من نحن',
            contact: 'اتصل بنا',
            activites: 'الأنشطة',
            members: 'الأعضاء',
            branches: 'الفروع',
            footer1: "مجلس عالي",
            footer2: "إتحاد مقاولي كوردستان"
        },
        krd: {
            home: 'سەرەتا',
            about: 'دەربارە',
            contact: 'پەیوەندی',
            activites: 'چالاکییەکان',
            members: 'ئەندامەکان',
            branches: 'لقەکان',
            footer1: "دەستەی باڵای",
            footer2: "یەکێتی بەڵێندەرانی کوردستان",
        }
    }

    useEffect(() => {
        if (lang !== "en") {
            $(".footer-layout1").css("direction", "rtl");
            $(".widget_title").addClass("widget_title_rtl");
            $(".lnk").addClass("lnk_rtl");

        }
    }, [])

    return (
        <footer className="footer-wrapper footer-layout1" data-bg-src="assets/img/bg/footer_bg_1.jpg">
            <div className="widget-area">
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-md-6">
                            <div className="widget newsletter-widget footer-widget">
                                <h3 className="widget_title">{keyword[lang].footer1}</h3>
                                <p className="footer-text">{keyword[lang].footer2}</p>
                                <div className="bg-shape" data-bg-src="assets/img/bg/bg_pattern_1.png" />
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="widget widget_nav_menu footer-widget">
                                <div class="menu-all-pages-container">
                                    <ul class="menu">
                                        <li><a className="lnk" href="/">{keyword[lang].home}</a></li>
                                        <li><Link className="lnk" to="/about">{keyword[lang].about}</Link></li>
                                        <li><Link className="lnk" to="/contact">{keyword[lang].contact}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="widget widget_nav_menu footer-widget">
                                <div class="menu-all-pages-container">
                                    <ul class="menu">
                                        <li><Link className="lnk" to="/members">{keyword[lang].members}</Link></li>
                                        <li><Link className="lnk" to="/our-branch">{keyword[lang].branches}</Link></li>
                                        <li><Link className="lnk" to="/activites">{keyword[lang].activites}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copyright-wrap">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-6">
                            <p className="copyright-text"><i className="fal fa-copyright" /> 2022 <a href="#">KCU</a>.</p>
                        </div>
                    </div>
                </div>
            </div>
            <a href="#" className="scrollToTop scroll-btn"><i className="far fa-long-arrow-up" /></a>
        </footer>

    );
}

export default Footer;