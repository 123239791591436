import Axios from "axios";
const Login = () => {
    return (
        <section className="space-top">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-5 col-md-12 form-section mb-5">
                        <div className="login-inner-form">
                            <div className="details text-center">
                                <h3>Sign Into Your Account</h3>
                                <form onSubmit={(e) => {
                                    e.preventDefault();
                                    let username = e.target.username.value;
                                    let password = e.target.password.value;
                                    Axios.post('/login', { username, password }).then((res) => {
                                        if (res.data !== "err") {
                                            window.location.assign("/dashboard");
                                        } else {
                                            window.location.assign("/login");
                                        }
                                    })
                                }}>
                                    <div className="form-group form-box">
                                        <input type="text" name="username" className="form-control" placeholder="Username" aria-label="username" />
                                    </div>
                                    <div className="form-group form-box">
                                        <input type="password" name="password" className="form-control" autoComplete="off" placeholder="Password" aria-label="Password" />
                                    </div>
                                    <div className="form-group">
                                        <button type="submit" className="btn btn-outline-primary w-100">Login</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;