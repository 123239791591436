import { useState, useEffect } from "react";
import Axios from "axios";
import $ from "jquery";
import Sidebar from "./Sidebar";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const Main = () => {

    const logout = () => {
        Axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }

    const [contentkrd, setContentKrd] = useState("");
    const [contenten, setContentEn] = useState("");
    const [contentar, setContentAr] = useState("");
    const Update_Main = (lang) => {
        var content = "";
        if (lang == "krd") {
            content = contentkrd;
        } else if (lang == "en") {
            content = contenten;
        } else if (lang == "ar") {
            content = contentar;
        }
        const data = {
            content: content,
            img: $(`#img_${lang}`).val(),
        }
        Axios.post(`/main/update/${lang}`, data).then(res => {
            window.location.reload();
        })
    }


    const upload = (lang) => {
        $(`.viewImg${lang}`).remove();
        $('<input type="file"  />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await Axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#img_${lang}`).val(res.data);
                    $(`#img${lang}`).append(`<img class="viewImg${lang}" src="${res.data}" width="100px" height="100px"style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;" />`)
                }
            })
        })
    }


    const [main_krd, setMain_krd] = useState("");
    const [main_en, setMain_en] = useState("");
    const [main_ar, setMain_ar] = useState("");
    useEffect(() => {
        $("body").addClass("sidebar-dark");
        Axios.get("/main/get").then(res => {
            setMain_en(res.data[0]);
            setMain_ar(res.data[1]);
            setMain_krd(res.data[2]);
        }).catch(err => {
            console.log(err);
        });
    }, []);

    return (
        <div className="main-wrapper">
            < Sidebar />
            <div className="page-wrapper">
                <nav className="navbar">
                    <a href="#0" className="sidebar-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </a>
                    <div className="navbar-content">

                        <ul className="navbar-nav">
                            <li className="nav-item" onClick={logout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <nav className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Admin</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Update Main</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-title">Main Kurdish</h6>
                                    <div className="mb-3">
                                        <label>Description</label>
                                        <CKEditor editor={ClassicEditor} config={{ language: 'ar' }} data={main_krd.content} onChange={(event, editor) => { const data = editor.getData(); setContentKrd(data) }} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Image</label>
                                        <div className="form-control text-center" onClick={() => { upload('krd') }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                        </div>
                                        <br />
                                        <div align="center" id="imgkrd">
                                            {main_krd.img && main_krd.img.length > 0 ?
                                                <img className="viewImgkrd" src={main_krd.img} width="100px" height="100px" style={{ border: 'solid #615dfa', marginBottom: 10, marginRight: 10, cursor: 'pointer' }} />
                                                : null}
                                            <input type="hidden" id="img_krd" value={main_krd.img} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-25 pull-right" onClick={() => { Update_Main('krd') }} >Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-title">تحدیث محتویات عن الموقع</h6>
                                    <div className="mb-3">
                                        <label>Description</label>
                                        <CKEditor editor={ClassicEditor} config={{ language: 'ar' }} data={main_ar.content} onChange={(event, editor) => { const data = editor.getData(); setContentAr(data) }} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Image</label>
                                        <div className="form-control text-center" onClick={() => { upload('ar') }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                        </div>
                                        <br />
                                        <div align="center" id="imgar">
                                            {main_ar.img && main_ar.img.length > 0 ?
                                                <img className="viewImgar" src={main_ar.img} width="100px" height="100px" style={{ border: 'solid #615dfa', marginBottom: 10, marginRight: 10, cursor: 'pointer' }} />
                                                : null}
                                            <input type="hidden" id="img_ar" value={main_ar.img} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-25 pull-right" onClick={() => { Update_Main('ar') }} >Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mt-5">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-title">Update Main Content</h6>
                                    <div className="mb-3">
                                        <label>Description</label>
                                        <CKEditor editor={ClassicEditor} data={main_en.content} onChange={(event, editor) => { const data = editor.getData(); setContentEn(data) }} />
                                    </div>
                                    <div className="mb-3">
                                        <label>Image</label>
                                        <div className="form-control text-center" onClick={() => { upload('en') }}>
                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                        </div>
                                        <br />
                                        <div align="center" id="imgen">
                                            {main_en.img && main_en.img.length > 0 ?
                                                <img className="viewImgen" src={main_en.img} width="100px" height="100px" style={{ border: 'solid #615dfa', marginBottom: 10, marginRight: 10, cursor: 'pointer' }} />
                                                : null}
                                            <input type="hidden" id="img_en" value={main_en.img} />
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-25 pull-right" type="button" onClick={() => { Update_Main('en') }} >Update</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.easy-soft.co/" target="_blank">KCU</a>.</p>
                </footer>
            </div >
        </div >
    );
}

export default Main;