import { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import axios from "axios";
import Swal from "sweetalert2";
import $ from "jquery";
import { toast } from 'react-toastify';

const Branch = () => {
    const logout = () => {
        axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }
    const [branch, setBranch] = useState([]);
    const getBranch = async () => {
        await axios.get('/branch').then((ok) => {
            setBranch(ok.data)
        })
    }

    useEffect(() => {
        getBranch();
        $("body").addClass("sidebar-dark");
    }, [])

    const [mapLink, setMapLink] = useState('');
    return (
        <div className="main-wrapper">
            < Sidebar />
            <div className="page-wrapper">
                <nav className="navbar">
                    <a href="/dashboard" className="sidebar-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </a>
                    <div className="navbar-content">
                        <ul className="navbar-nav">
                            <li className="nav-item" type="button" onClick={logout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <nav className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <a href="/dashboard">Dashboard</a>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">Branches</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-lg-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <h4 className="card-title">Branches</h4>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <button type="button" className="btn btn-dark btn-sm float-right" data-bs-toggle="modal" data-bs-target="#newPost">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                                <span className="ml-1">New Branch</span>
                                            </button>
                                            <div className="modal fade" id="newPost" tabIndex={-1} aria-labelledby="newPost" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title" id="newPostlabel">Add New Branch</h5>
                                                            <button type="button" className="btn-close" data-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                        <form className="forms-sample" onSubmit={e => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const data = Object.fromEntries(formData);
                                                            axios.post("/branch", data).then(res => {
                                                                if (res.data !== "err") {
                                                                    getBranch();
                                                                    e.target.reset();
                                                                    window.$('#newPost').modal('hide')
                                                                }
                                                                else {
                                                                    toast.error('Error adding post')
                                                                }
                                                            })
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="lang" className="form-label">Select Language</label>
                                                                    <select className="form-select" name="lang" id="lang" onChange={(e) => {
                                                                        if (e.target.value !== null) {
                                                                            $('.member-div').show();
                                                                        } else {
                                                                            $('.member-div').hide();
                                                                        }
                                                                    }}>
                                                                        <option value={null}>....</option>
                                                                        <option value="krd">Kurdish</option>
                                                                        <option value="ar">Arabic</option>
                                                                        <option value="en">English</option>
                                                                        <option value="tr">Turkish </option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3 member-div" style={{ display: "none" }}>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="title" className="form-label">Title</label>
                                                                        <input type="text" className="form-control" name="title" placeholder="Branch Title" required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="phone" className="form-label">Mobile</label>
                                                                        <input type="text" className="form-control" name="phone" placeholder="Phone Number" required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="address" className="form-label">Address</label>
                                                                        <textarea className="form-control" name="address" rows={3} placeholder="Address" required />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="map" className="form-label">Map Link</label>
                                                                        <input type="text" className="form-control" name="map" id="mpl" placeholder="Map Link" required
                                                                            onChange={(e) => {
                                                                                $("#mpl").val(e.target.value.split('src="')[1].split('"')[0]);
                                                                            }} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                <button type="submit" className="btn btn-dark">Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Title</th>
                                                    <th>Phone</th>
                                                    <th>Address</th>
                                                    <th>Language</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {branch && branch.map((item, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.title}</td>
                                                        <td>{item.phone}</td>
                                                        <td>{item.address}</td>
                                                        <td>{item.lang}</td>
                                                        <td>
                                                            <button className="btn btn-danger btn-sm mx-2"
                                                                onClick={() => {
                                                                    Swal.fire({
                                                                        title: 'Are you sure?',
                                                                        text: "You won't be able to revert this!",
                                                                        icon: 'warning',
                                                                        showCancelButton: true,
                                                                        confirmButtonColor: '#3085d6',
                                                                        cancelButtonColor: '#d33',
                                                                        confirmButtonText: 'Yes, delete it!'
                                                                    }).then((result) => {
                                                                        if (result.isConfirmed) {
                                                                            axios.delete(`/branch/${item.id}`).then(res => {
                                                                                if (res.data) {
                                                                                    getBranch();
                                                                                    Swal.fire({
                                                                                        title: 'Deleted!',
                                                                                        text: 'Your file has been deleted.',
                                                                                        icon: 'success',
                                                                                        showConfirmButton: false,
                                                                                        timer: 1500
                                                                                    })
                                                                                }
                                                                            })
                                                                        }
                                                                    })
                                                                }} >
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-trash"><polyline points="3 6 5 6 21 6" /><path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2" /></svg>
                                                            </button>
                                                            <button type="button" className="btn btn-warning btn-sm" data-bs-toggle="modal" data-bs-target={`#editPost${item.id}`}>
                                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" /><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" /></svg>
                                                            </button>
                                                            <div className="modal fade" id={`editPost${item.id}`} tabIndex={-1} aria-labelledby={`editPost${item.id}`} aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title" id="newPostlabel">Add New Branch</h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                                        </div>
                                                                        <form className="forms-sample" onSubmit={e => {
                                                                            e.preventDefault();
                                                                            const formData = new FormData(e.target);
                                                                            const data = Object.fromEntries(formData);
                                                                            axios.put(`/branch/${item.id}`, data).then(res => {
                                                                                if (res.data !== "err") {
                                                                                    getBranch();
                                                                                    e.target.reset();
                                                                                    window.$(`#editPost${item.id}`).modal('hide')
                                                                                }
                                                                                else {
                                                                                    toast.error('Error adding post')
                                                                                }
                                                                            })
                                                                        }}>
                                                                            <div className="modal-body">
                                                                                <div className="mb-3">
                                                                                    <input type="hidden" className="form-control" name="lang" defaultValue={item.lang} required />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="title" className="form-label">Title</label>
                                                                                    <input type="text" className="form-control" name="title" defaultValue={item.title} required />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="phone" className="form-label">Mobile</label>
                                                                                    <input type="text" className="form-control" name="phone" defaultValue={item.phone} required />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="address" className="form-label">Address</label>
                                                                                    <textarea className="form-control" name="address" rows={3} defaultValue={item.address} required />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="map" className="form-label">Map Link</label>
                                                                                    <input type="text" className="form-control" name="map" id={`mpl_${item.id}`} placeholder="Map Link" required
                                                                                        onChange={(e) => {
                                                                                            $(`#mpl_${item.id}`).val(e.target.value.split('src="')[1].split('"')[0]);
                                                                                        }} />
                                                                                </div>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                                <button type="submit" className="btn btn-dark">Submit</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.easy-soft.co/" target="_blank" rel="noreferrer">KCU</a>.</p>
                </footer>
            </div>
        </div>
    );
}

export default Branch;