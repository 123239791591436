import { useEffect, useState } from "react";
import Axios from "axios";
import $ from 'jquery';

const Contact = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            home: 'Home',
            contact: 'Contact Us',
            contactInfo: 'Contact Info',
            contactNumber: 'Contact Number',
            mailAddress: 'Mail Address',
            officeAddress: 'Office Address'
        },
        ar: {
            home: 'الرئيسية',
            contact: 'اتصل بنا',
            contactInfo: 'معلومات الاتصال',
            contactNumber: 'رقم الاتصال',
            mailAddress: 'عنوان البريد الإلكتروني',
            officeAddress: 'عنوان المكتب'
        },
        krd: {
            home: 'سەرەتا',
            contact: 'پەیوەندی',
            contactInfo: 'زانیاری پەیوەندی',
            contactNumber: 'ژمارەی پەیوەندی',
            mailAddress: 'ناونیشانی ئیمەیل',
            officeAddress: 'ناونیشانی ئۆفیس'
        }
    }

    const [contact, setContact] = useState({});
    const getContact = async () => {
        const res = await Axios.get(`/contactus?lang=${lang}`);
        setContact(res.data[0]);
    }
    useEffect(() => {
        getContact();
        setTimeout(() => {
            if (lang !== "en") {
                $(".space-top").css("direction", "rtl");
                $(".contact-info_icon").addClass("contact-info_icon_rtl");
            }
        }, 500);
        document.title = keyword[lang].contact;
    }, []);

    return (
        <div>
            <div className="breadcumb-wrapper background-image" data-overlay="overlay1" data-opacity={9} style={{ backgroundImage: 'url("assets/img/breadcumb/breadcumb-bg.jpg")' }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">{keyword[lang].contact}</h1>
                        <ul className="breadcumb-menu">
                            <li className="cump-title"><a href="/">{keyword[lang].home}</a></li>
                            <li>{keyword[lang].contact}</li>
                        </ul>
                    </div>
                </div>
                <div className="breadcumb-shape"><img src="assets/img/breadcumb/shape.png" alt="shape" /></div>
            </div>
            <section className="space-top">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5 mb-5 pb-lg-4">
                            <h3 className="border-title2">{keyword[lang].contactInfo}</h3>
                            <div className="contact-info">
                                <h4 className="contact-info_title">{keyword[lang].contactNumber}</h4>
                                <div className="contact-info_icon"><i className="fal fa-phone" /></div>
                                <div className="media-body"><span className="contact-info_text"><a href={`tel:${contact.phone1}`}>{contact.phone1}</a> <a href={`tel:${contact.phone2}`}>{contact.phone2}</a></span></div>
                            </div>
                            <div className="contact-info">
                                <h4 className="contact-info_title">{keyword[lang].mailAddress}</h4>
                                <div className="contact-info_icon"><i className="fal fa-envelope" /></div>
                                <div className="media-body"><span className="contact-info_text"><a href={`mailto:${contact.email1}`}>{contact.email1}</a> <a href={`mailto:${contact.email2}`}>{contact.email2}</a></span></div>
                            </div>
                            <div className="contact-info">
                                <h4 className="contact-info_title">{keyword[lang].officeAddress}</h4>
                                <div className="contact-info_icon"><i className="fal fa-location-dot" /></div>
                                <div className="media-body"><span className="contact-info_text">{contact.address}</span></div>
                            </div>
                        </div>
                        <div className="col-lg-7 align-self-end">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.7310056272386!2d89.2286059153658!3d24.00527418490799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe9b97badc6151%3A0x30b048c9fb2129bc!2sAngfuzsoft!5e0!3m2!1sen!2sbd!4v1651028958211!5m2!1sen!2sbd"
                                height={450} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                        </div>
                    </div>
                </div>
            </section>
        </div>

    );
}

export default Contact;