import React, { useEffect, useState } from "react";
import Axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import Sidebar from "./Sidebar";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ActivityList = () => {

    const logout = () => {
        Axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }

    const [content, setContent] = useState("")
    const [activity, setActivity] = useState([]);
    const getActivity = async () => {
        const res = await Axios.get("/activity");
        setActivity(res.data);
        setTimeout(() => {
            window.$('.dt-tbl').DataTable();
        }, 500);
    };

    const upload = () => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await Axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $("#img").val(res.data);
                        $('#upimg').append(`<img class="viewImg" src="${res.data}" width="100px" height="100px"style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;" />`)
                    }
                    $('.viewImg').click(function () {
                        $(this).remove()
                    })
                })
            }
        })
    }

    const uploadModify = (id) => {
        $('<input type="file"  />').click().on('change', async (e) => {
            for (let i = 0; i < e.target.files.length; i++) {
                var formData = new FormData();
                formData.append('file', e.target.files[i]);
                await Axios.post('/upload', formData).then(res => {
                    if (res.data !== "err") {
                        $(`#img_${id}`).val(res.data);
                        $(`#upimg_${id}`).append(`<img class="viewImg_${id}" src="${res.data}" width="100px" height="100px"style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;" />`)
                    }
                    $(`.viewImg_${id}`).click(function () {
                        $(this).remove()
                    })
                })
            }
        })
    }

    useEffect(() => {
        getActivity();
        $("body").addClass("sidebar-dark");
    }, []);
    return (
        <div className="main-wrapper">
            < Sidebar />
            <div className="page-wrapper">
                <nav className="navbar">
                    <a href="#0" className="sidebar-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </a>
                    <div className="navbar-content">

                        <ul className="navbar-nav">
                            <li className="nav-item" onClick={logout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <nav className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Admin</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Activity List</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h6 className="card-title">Activity List</h6>
                                        </div>
                                        <div className="col-md-6 d-flex justify-content-end">
                                            <button className="btn btn-sm btn-dark" data-bs-toggle="modal" data-bs-target="#newActivity">
                                                <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-plus"><line x1={12} y1={5} x2={12} y2={19} /><line x1={5} y1={12} x2={19} y2={12} /></svg>
                                                <span className="ms-2">New Activity</span>
                                            </button>
                                            <div className="modal fade" id="newActivity" tabIndex={-1} aria-labelledby="newActivity" aria-hidden="true">
                                                <div className="modal-dialog modal-lg">
                                                    <div className="modal-content">
                                                        <div className="modal-header">
                                                            <h5 className="modal-title">Insert New Activity </h5>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                        </div>
                                                        <form className="form-sample" onSubmit={(e) => {
                                                            e.preventDefault();
                                                            const formData = new FormData(e.target);
                                                            const src = [];
                                                            $('.viewImg').each(function () {
                                                                src.push($(this).attr('src'))
                                                            })
                                                            formData.append('src', src);
                                                            const data = Object.fromEntries(formData);
                                                            Axios.post(`/activity`, data).then(res => {
                                                                if (res.data !== 'done') {
                                                                    toast.error(res.data, {
                                                                        position: "top-left",
                                                                        autoClose: 3000,
                                                                        hideProgressBar: false,
                                                                        closeOnClick: true,
                                                                        pauseOnHover: true,
                                                                        draggable: true,
                                                                        progress: undefined,
                                                                    });
                                                                } else {
                                                                    e.target.reset();
                                                                    getActivity();
                                                                    toast.success("Activity Inserted Successfully")
                                                                    window.$('#newActivity').modal('hide')
                                                                }
                                                            });
                                                        }}>
                                                            <div className="modal-body">
                                                                <div className="mb-3">
                                                                    <label htmlFor="lang" className="form-label">Select Language</label>
                                                                    <select className="form-select" name="lang" id="lang" onChange={(e) => {
                                                                        if (e.target.value !== null) {
                                                                            if (e.target.value === "en") {
                                                                                $('#setLang').text('English');
                                                                            } else if (e.target.value === "ar") {
                                                                                $('#setLang').text('Arabic');
                                                                            } else if (e.target.value === "krd") {
                                                                                $('#setLang').text('Kurdish');
                                                                            }
                                                                            $('.activity-div').show();
                                                                        } else {
                                                                            $('.activity-div').hide();
                                                                        }
                                                                    }}>
                                                                        <option value={null}>....</option>
                                                                        <option value="krd">Kurdish</option>
                                                                        <option value="ar">Arabic</option>
                                                                        <option value="en">English</option>
                                                                    </select>
                                                                </div>
                                                                <div className="mb-3 activity-div" style={{ display: "none" }}>
                                                                    <div className="mb-3">
                                                                        <label htmlFor="title" className="form-label">Title <span className="text-danger" id="setLang"></span></label>
                                                                        <input type="text" className="form-control" name="title" placeholder="Title" />
                                                                    </div>

                                                                    <div className="mb-3">
                                                                        <label htmlFor="content" className="form-label">Content</label>
                                                                        <CKEditor editor={ClassicEditor} config={{ language: 'ar' }} onChange={(event, editor) => { const data = editor.getData(); setContent(data) }} />
                                                                        <input type="hidden" name="content" value={content} />
                                                                    </div>
                                                                    <div className="mb-3">
                                                                        <label> Image</label>
                                                                        <input type="hidden" name="img" id="img" />
                                                                        <div className="form-control text-center" onClick={upload}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                        </div>
                                                                        <br />
                                                                        <div align="center" id="upimg"></div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                <button type="submit" className="btn btn-dark"> Submit</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Image</th>
                                                    <th>Title</th>
                                                    <th>Language</th>
                                                    <th>Created</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {activity.map((activity, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td><img src={activity.img} alt="activity" className="img-fluid" style={{ width: "50px", height: "50px" }} /></td>
                                                        <td>{activity.title}</td>
                                                        <td>{activity.lang}</td>
                                                        <td>{new Date(activity.created).toLocaleDateString()}</td>
                                                        <td>
                                                            <button className="btn btn-small btn-primary mx-2" data-bs-toggle="modal" data-bs-target={`#newActivity${activity.id}`}>Edit</button>
                                                            <div className="modal fade" id={`newActivity${activity.id}`} tabIndex={-1} aria-labelledby={`newActivity${activity.id}`} aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Update {activity.full_name} Information </h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                        </div>
                                                                        <form className="form-sample" onSubmit={(e) => {
                                                                            e.preventDefault();
                                                                            const formData = new FormData(e.target);
                                                                            const src = [];
                                                                            $(`.viewImg_${activity.id}`).each(function () {
                                                                                src.push($(this).attr('src'))
                                                                            })
                                                                            formData.append('src', src);
                                                                            const data = Object.fromEntries(formData);

                                                                            Axios.put(`/activity/${activity.id}`, data).then(res => {
                                                                                if (res.data !== 'done') {
                                                                                    toast.error(res.data, {
                                                                                        position: "top-left",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        progress: undefined,
                                                                                    });
                                                                                } else {
                                                                                    e.target.reset();
                                                                                    getActivity();
                                                                                    toast.success("Activity Updated Successfully")
                                                                                    window.$(`#newActivity${activity.id}`).modal('hide')
                                                                                }
                                                                            });
                                                                        }}>
                                                                            <div className="modal-body">
                                                                                <div className="mb-3">
                                                                                    <label htmlFor="title" className="form-label">Title </label>
                                                                                    <input type="text" className="form-control" name="title" placeholder="Title" defaultValue={activity.title} />
                                                                                </div>

                                                                                <div className="mb-3">
                                                                                    <label htmlFor="content" className="form-label">Content</label>
                                                                                    <CKEditor editor={ClassicEditor} config={{ language: 'ar' }} data={activity.content} onChange={(event, editor) => { const data = editor.getData(); setContent(data) }} />
                                                                                    <input type="hidden" name="content" value={content} />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label> Image</label>
                                                                                    <input type="hidden" name="img" id="img" defaultValue={activity.img} />
                                                                                    <div className="form-control text-center" onClick={() => { uploadModify(activity.id) }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                                    </div>
                                                                                    <br />
                                                                                    <div align="center" id={`upimg_${activity.id}`}>
                                                                                        <img className={`viewImg_${activity.id}`} src={activity.img} width="100px" height="100px" style={{ border: 'solid #615dfa', marginBottom: 10, marginRight: 10, cursor: 'pointer' }} onClick={() => { uploadModify(activity.id) }} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                                <button type="submit" className="btn btn-dark"> Update</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <button className="btn btn-small btn-danger" onClick={() => {
                                                                Swal.fire({
                                                                    title: 'Are you sure?',
                                                                    text: "You won't be able to revert this!",
                                                                    icon: 'warning',
                                                                    showCancelButton: true,
                                                                    confirmButtonColor: '#3085d6',
                                                                    cancelButtonColor: '#d33',
                                                                    confirmButtonText: 'Yes, delete it!'
                                                                }).then((result) => {
                                                                    if (result.isConfirmed) {
                                                                        Axios.delete(`/activity/${activity.id}`).then(res => {
                                                                            if (res.data !== 'done') {
                                                                                toast.error(res.data, {
                                                                                    position: "top-left",
                                                                                    autoClose: 3000,
                                                                                    hideProgressBar: false,
                                                                                    closeOnClick: true,
                                                                                    pauseOnHover: true,
                                                                                    draggable: true,
                                                                                    progress: undefined,
                                                                                });
                                                                            } else {
                                                                                window.location.reload();
                                                                            }
                                                                        });
                                                                    }
                                                                })
                                                            }}>Delete</button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.easy-soft.co/" target="_blank">KCU</a>.</p>
                </footer>
            </div >
        </div >
    );
}


export default ActivityList;