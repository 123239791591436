import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import $ from 'jquery';

const Header = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            home: 'Home',
            about: 'About Us',
            contact: 'Contact Us',
            activites: 'Activites',
            members: 'Members',
            branches: 'Branches',
            kurdistan: 'Kurdistan',
            contractors: 'Contractors',
            union: 'Union'
        },
        ar: {
            home: 'الرئيسية',
            about: 'من نحن',
            contact: 'اتصل بنا',
            activites: 'الأنشطة',
            members: 'الأعضاء',
            branches: 'الفروع',
            kurdistan: 'إتحاد',
            contractors: 'مقاولي',
            union: 'كردستان'
        },
        krd: {
            home: 'سەرەتا',
            about: 'دەربارە',
            contact: 'پەیوەندی',
            activites: 'چالاکییەکان',
            members: 'ئەندامەکان',
            branches: 'لقەکان',
            kurdistan: 'یەکێتی',
            contractors: 'بەڵێندەرانی',
            union: 'کوردستان'
        }
    }
    const switchLang = (str) => {
        localStorage.setItem('lang', str);
        window.location.reload();
    }

    const [contact, setContact] = useState({});
    const getContact = async () => {
        const res = await Axios.get(`/contactus?lang=${lang}`);
        setContact(res.data[0]);
    }
    const [social, setSocial] = useState({});
    const getSocial = async () => {
        const res = await Axios.get(`/social`);
        setSocial(res.data[0]);
    }

    // get mobile width
    const [width, setWidth] = useState(window.innerWidth);
    const updateWidth = () => {
        setWidth(window.innerWidth);
    }

    useEffect(() => {
        updateWidth();
        window.addEventListener("resize", updateWidth);
        getContact();
        getSocial();
        return () => window.removeEventListener("resize", updateWidth);
    }, []);

    useEffect(() => {
        if (lang !== "en") {
            $(".header-menu").css("direction", "rtl");
            $(".xd").addClass("xmobile");
        }
        if (width < 768) {
            $("#logx").addClass("logomob");
            $("#logo2").addClass("mt-3");
            $("#logo2").addClass("mb-3");
            $("#logo1").after("<br />")
            $("#logo2").after("<br />")
        } else {
            $("#logx").removeClass("logomob");
            $("#logo1").after(" ")
            $("#logo2").after(" ")
            $("#logo2").removeClass("mt-3");
            $("#logo2").removeClass("mb-3");
        }
    }, [])


    return (
        <div>
            <div className="as-menu-wrapper">
                <div className="as-menu-area text-center"><button className="as-menu-toggle"><i className="fal fa-times" /></button>
                    <div className="mobile-logo">
                        <a href="/">
                            <h4>
                                {keyword[lang].kurdistan}  <br />
                                <span style={{ color: '#ff4c13' }}>{keyword[lang].contractors} </span>
                                {keyword[lang].union}
                            </h4>
                        </a>
                    </div>
                    <div className="as-mobile-menu">
                        <ul className="header-mobile">
                            <li><a className="xd" href>{keyword[lang].home}</a></li>
                            <li><Link className="xd" to="/members">{keyword[lang].members}</Link></li>
                            <li><Link className="xd" to="/activites">{keyword[lang].activites}</Link></li>
                            <li><Link className="xd" to="/our-branch">{keyword[lang].branches}</Link></li>
                            <li><Link className="xd" to="/about">{keyword[lang].about}</Link></li>
                            <li><Link className="xd" to="/contact">{keyword[lang].contact}</Link></li>

                        </ul>
                    </div>
                </div>
            </div>
            <header className="as-header header-layout1">
                <div className="header-top">
                    <div className="container">
                        <div className="header-top-inner">
                            <div className="row justify-content-center justify-content-md-between align-items-center gy-2">
                                <div className="col-auto d-none d-md-block">
                                    <div className="header-links d-none d-md-block">
                                        <ul>
                                            <li><i className="fal fa-phone" /><a href={`tel:${contact.phone1}`}>{contact.phone1}</a></li>
                                            <li><i className="fal fa-envelope" /><a href={`mailto:${contact.email1}`}>{contact.email1}</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <div className="header-links">
                                        <ul>
                                            <li>
                                                <i className="fal fa-globe" />
                                                <a className="dropdown-toggle" href="#" role="button" id="dropdownMenuLink6" data-bs-toggle="dropdown" aria-expanded="false">{lang === 'en' ? 'English' : lang === 'ar' ? 'العربية' : 'کوردی'}  </a>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink6">
                                                    <li>
                                                        <a href="#0" onClick={() => switchLang('krd')} data-lang="krd">کوردی</a>
                                                        <a href="#0" onClick={() => switchLang('ar')} data-lang="ar">عربي</a>
                                                        <a href="#0" onClick={() => switchLang('en')} data-lang="en">English</a>

                                                    </li>
                                                </ul>
                                            </li>
                                            <li>
                                                <div className="header-social">
                                                    <a href={social.fb} target="_blank">
                                                        <i className="fab fa-facebook-f" />
                                                    </a>
                                                    <a href={social.twitter} target="_blank">
                                                        <i className="fab fa-twitter" />
                                                    </a>
                                                    <a href={social.ig} target="_blank">
                                                        <i className="fab fa-instagram" />
                                                    </a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="sticky-wrapper">
                    <div className="sticky-active">
                        <div className="menu-area">
                            <div className="container">
                                <div className="row align-items-center justify-content-between">
                                    <div className="col-auto">
                                        <div className="logo-style1">
                                            <a href="/">
                                                <a href="/">
                                                    <h4 id="logx" style={{ paddingBottom: '40px' }}>
                                                        <span id="logo1">{keyword[lang].kurdistan} </span>
                                                        <span id="logo2" style={{ color: '#ff4c13' }}>{keyword[lang].contractors} </span>
                                                        <span id="logo3">{keyword[lang].union}</span>
                                                    </h4>
                                                </a>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-auto">
                                        <div className="row align-items-center">
                                            <div className="col-auto">
                                                <nav className="main-menu d-none d-lg-inline-block">
                                                    <ul className="header-menu">
                                                        <li><a href="/">{keyword[lang].home}</a></li>
                                                        <li><Link to="/members">{keyword[lang].members}</Link></li>
                                                        <li><Link to="/activites">{keyword[lang].activites}</Link></li>
                                                        <li><Link to="/our-branch">{keyword[lang].branches}</Link></li>
                                                        <li><Link to="/about">{keyword[lang].about}</Link></li>
                                                        <li><Link to="/contact">{keyword[lang].contact}</Link></li>
                                                    </ul>
                                                </nav>
                                                <button type="button" className="as-menu-toggle  d-inline-block d-lg-none">
                                                    <i className="far fa-bars" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </div>

    );
}

export default Header;