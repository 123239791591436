import Axios from "axios";
import { useState, useEffect } from "react";

const Members = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            members: 'Members',
            latestMembers: 'Latest Members',
        },
        ar: {
            members: 'الأعضاء',
            latestMembers: 'أحدث الأعضاء',
        },
        krd: {
            members: 'ئەندامەکان',
            latestMembers: 'دوایین ئەندامەکان',
        }
    }
    const [selectedYear, setSelectedYear] = useState(2022);
    const [members, setMembers] = useState([]);
    const getMembers = async () => {
        const res = await Axios.get(`/memberss?lang=${lang}&year=${selectedYear}`);
        setMembers(res.data);
    }
    useEffect(() => {
        getMembers();
        document.title = keyword[lang].members;
    }, [selectedYear]);
    return (
        <div className="section">

            <section className="bg-smoke2 space" data-bg-src="assets/img/bg/service_bg_1.png">
                <div className="container z-index-common">
                    <div className="title-area text-center">
                        <div className="shadow-title">{keyword[lang].members}</div>
                        <span className="sub-title justify-content-center">
                            <span className="shape left"><span className="dots" /></span> {keyword[lang].members} <span className="shape right"><span className="dots" /></span>
                        </span>
                        <ul className="header-links d-none d-md-block">
                            {(() => {
                                let arr = [];
                                for (let i = 2000; i <= new Date().getFullYear(); i++) {
                                    arr.push(i);
                                }
                                return arr;
                            })().map((year, index) => {
                                return (
                                    <li><button className="btn btn-default" key={index} onClick={() => setSelectedYear(year)}>{year}</button></li>
                                )
                            })}
                        </ul>
                    </div>
                    <div className="row gy-40">
                        {members.map((item, index) => (
                            <div className="col-md-6 col-lg-4 col-xxl-3" key={index}>
                                <div className="as-team team-card">
                                    <div className="team-img">
                                        <img src={item.img} alt={item.full_name} style={{ height: "325px" }} />
                                    </div>
                                    <div className="team-content background-image" style={{ backgroundImage: 'url("assets/img/bg/bg_pattern_9.png")' }}>
                                        <div className="team-social">
                                            <a href={`tel:${item.phone}`} className="play-btn">
                                                <i className="fa fa-phone" aria-hidden="true" />
                                            </a>
                                        </div>
                                        <h3 className="team-title">{item.full_name}</h3>
                                        <span className="team-desig">{item.role}</span>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Members;