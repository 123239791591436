import { useState } from "react";
import $ from "jquery";
import Axios from "axios";

const Sidebar = () => {
    useState(() => {
        Axios.get("/auth").then(res => {
            if (res.data === "err") {
                window.location.assign("/login");
            }
        });
        $('#front').remove();
    }, []);

    return (
        <nav className="sidebar">
            <div className="sidebar-header">
                <a href="/dashboard" className="sidebar-brand">
                    KCU<span>HB</span>
                </a>
                <div className="sidebar-toggler not-active">
                    <span />
                    <span />
                    <span />
                </div>
            </div>
            <div className="sidebar-body">
                <ul className="nav">
                    <li className="nav-item nav-category">Main</li>
                    <li className="nav-item">
                        <a href="/update-main" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-info"><circle cx={12} cy={12} r={10} /><line x1={12} y1={16} x2={12} y2={12} /><line x1={12} y1={8} x2="12.01" y2={8} /></svg>
                            <span className="link-title">Main</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/activity-list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-file-text"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" /><polyline points="14 2 14 8 20 8" /><line x1={16} y1={13} x2={8} y2={13} /><line x1={16} y1={17} x2={8} y2={17} /><polyline points="10 9 9 9 8 9" /></svg>
                            <span className="link-title">Activity List</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/member-list" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-users"><path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" /><circle cx={9} cy={7} r={4} /><path d="M23 21v-2a4 4 0 0 0-3-3.87" /><path d="M16 3.13a4 4 0 0 1 0 7.75" /></svg>
                            <span className="link-title">Member List</span>
                        </a>
                    </li>
                    <li className="nav-item nav-category">Update Info</li>
                    <li className="nav-item">
                        <a href="/update-about" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-info"><circle cx={12} cy={12} r={10} /><line x1={12} y1={16} x2={12} y2={12} /><line x1={12} y1={8} x2="12.01" y2={8} /></svg>
                            <span className="link-title">About</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/update-contact" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-phone-call"><path d="M15.05 5A5 5 0 0 1 19 8.95M15.05 1A9 9 0 0 1 23 8.94m-1 7.98v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z" /></svg>
                            <span className="link-title">Contact</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/update-slider" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-sliders"><line x1={4} y1={21} x2={4} y2={14} /><line x1={4} y1={10} x2={4} y2={3} /><line x1={12} y1={21} x2={12} y2={12} /><line x1={12} y1={8} x2={12} y2={3} /><line x1={20} y1={21} x2={20} y2={16} /><line x1={20} y1={12} x2={20} y2={3} /><line x1={1} y1={14} x2={7} y2={14} /><line x1={9} y1={8} x2={15} y2={8} /><line x1={17} y1={16} x2={23} y2={16} /></svg>
                            <span className="link-title">Sliders</span>
                        </a>
                    </li>
                    <li className="nav-item">
                        <a href="/branchs" className="nav-link">
                            <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="link-icon feather feather-share-2"><circle cx={18} cy={5} r={3} /><circle cx={6} cy={12} r={3} /><circle cx={18} cy={19} r={3} /><line x1="8.59" y1="13.51" x2="15.42" y2="17.49" /><line x1="15.41" y1="6.51" x2="8.59" y2="10.49" /></svg>
                            <span className="link-title">Branch</span>
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;