import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Header from "./layout/Header";
import Footer from "./layout/Footer";
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Members from './components/Members';
import Activites from './components/Activites';
import Activity from './components/Activity';
import OurBranch from './components/Branches';
import Login from './components/Login';

import ActivityList from './components/admin/ActivityList';
import MemberList from './components/admin/MemberList';
import UpdateAbout from './components/admin/UpdateAbout';
import UpdateContact from './components/admin/UpdateContact';
import UpdateSlider from './components/admin/UpdateSlider';
import UpdateSocial from './components/admin/UpdateSocial';
import Branch from './components/admin/Branch';
import Main from './components/admin/Main';


function App() {
  return (
    <>
      <ToastContainer />
      <Router>
        <Routes>
          <Route exact path="/" element={<><Header /><Home /> <Footer /></>} />
          <Route exact path="/about" element={<><Header /><About /> <Footer /></>} />
          <Route exact path="/contact" element={<><Header /><Contact /> <Footer /></>} />
          <Route exact path="/members" element={<><Header /><Members /> <Footer /></>} />
          <Route exact path="/activites" element={<><Header /><Activites /> <Footer /></>} />
          <Route exact path="/activity/:id" element={<><Header /><Activity /> <Footer /></>} />
          <Route exact path="/our-branch" element={<><Header /><OurBranch /> <Footer /></>} />
          <Route exact path="/login" element={<><Header /><Login /> <Footer /></>} />

          <Route exact path="/dashboard" element={<ActivityList />} />
          <Route exact path="//activity-list" element={<ActivityList />} />
          <Route exact path="/member-list" element={<MemberList />} />
          <Route exact path="/update-main" element={<Main />} />
          <Route exact path="/update-about" element={<UpdateAbout />} />
          <Route exact path="/update-contact" element={<UpdateContact />} />
          <Route exact path="/update-slider" element={<UpdateSlider />} />
          <Route exact path="/update-social" element={<UpdateSocial />} />
          <Route exact path="/branchs" element={<Branch />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
