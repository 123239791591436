/* eslint-disable */
import { useState, useEffect } from "react";
import Axios from "axios";

const Branches = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';

    const keyword = {
        en: {
            branches: 'Branches'
        },
        ar: {
            branches: 'الفروع'
        },
        krd: {
            branches: 'لقەکان'
        }
    }
    const [branches, setBranches] = useState([]);
    const getBranches = async () => {
        const res = await Axios.get(`/branch?lang=${lang}`);
        setBranches(res.data);
    };
    useEffect(() => {
        getBranches();
    }, []);

    return (
        <div>
            <section className="bg-cover" style={{ background: 'url(assets/img/banner-2.png) no-repeat' }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="text-center py-5 mt-3 mb-3">
                                <h1 className="ft-medium mb-3">{keyword[lang].branches}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="py-2">
                <div className="container">
                    <div className="row">
                        <div className="colxl-12 col-lg-12 col-md-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href="/">Home</a></li>
                                    <li className="breadcrumb-item"><span>Pages</span></li>
                                    <li className="breadcrumb-item active" aria-current="page">Branches</li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
            </section>
            <section className="middle">
                <div className="container">
                    <div className="row align-items-center rows-products">
                        {branches && branches.map((item, index) => (
                            <div className="col-md-4 col-6 mb-3 mt-3" key={index}>
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="card-title">{item.title}</h4>
                                        <h5 className="card-title">{item.phone}</h5>
                                        <p className="card-text">{item.address}</p>
                                    </div>
                                    <div className="card-body">
                                        <iframe src={item.map} style={{ border: 0 }} allowFullScreen loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Branches;