import { useEffect, useState } from "react";
import Axios from "axios";
import $ from 'jquery';
import { Link } from "react-router-dom";

const About = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            home: 'Home',
            about: 'About Us'
        },
        ar: {
            home: 'الرئيسية',
            about: 'من نحن'
        },
        krd: {
            home: 'سەرەتا',
            about: 'دەربارە'
        }
    }

    const [about, setAbout] = useState({});
    const getAbout = async () => {
        const res = await Axios.get(`/aboutus?lang=${lang}`);
        setAbout(res.data[0]);
    }
    useEffect(() => {
        getAbout();
        setTimeout(() => {
            if (lang !== "en") {
                $(".about-shape").css("direction", "rtl");
                $(".shape-right").removeClass("right").addClass("left");
                $(".cump-title").addClass("cump_rtl");
            }
        }, 500);
        document.title = keyword[lang].about;
    }, []);

    return (
        <div className="section">
            <div className="breadcumb-wrapper background-image" data-overlay="overlay1" data-opacity={9} style={{ backgroundImage: 'url("assets/img/breadcumb/breadcumb-bg.jpg")' }}>
                <div className="container z-index-common">
                    <div className="breadcumb-content">
                        <h1 className="breadcumb-title">{keyword[lang].about}</h1>
                        <ul className="breadcumb-menu">
                            <li className="cump-title"><a href="/">{keyword[lang].home}</a></li>
                            <li>{keyword[lang].about}</li>
                        </ul>
                    </div>
                </div>
                <div className="breadcumb-shape"><img src="assets/img/breadcumb/shape.png" alt="shape" /></div>
            </div>
            <div className="space" id="about-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="img-box1"><img src={about.img} alt="About" />
                                <div className="shape"><img src="assets/img/normal/about_shape_1.svg" alt="shape" /></div>
                            </div>
                        </div>
                        <div className="col-xl-6 ps-3 ps-xl-5">
                            <div className="shape about-shape">
                                <img src="assets/img/bg/bg_pattern_4.png" alt="bg" />
                                <div className="title-area mb-30"><span className="sub-title">{keyword[lang].about} <span className="shape shape-right right"><span className="dots" /></span></span>
                                    <h2 className="sec-title">{about.title}</h2>
                                </div>
                                <p className="mt-n2 mb-4">
                                    <div dangerouslySetInnerHTML={{ __html: about.content }} />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default About;