import { useState, useEffect } from "react";
import Axios from "axios";
import $ from "jquery";
import Sidebar from "./Sidebar";

const UpdateContact = () => {

    const logout = () => {
        Axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }

    const [contact, setContact] = useState([]);
    const getCotact = async () => {
        const res = await Axios.get("/contact/get");
        setContact(res.data[0]);
    };

    const [address_krd, setAddress_krd] = useState("");
    const [address_en, setAddress_en] = useState("");
    const [address_ar, setAddress_ar] = useState("");
    const getAddress = async () => {
        const res = await Axios.get("/contact/get");
        setAddress_en(res.data[0]);
        setAddress_krd(res.data[1]);
        setAddress_ar(res.data[2]);
    };

    useEffect(() => {
        getCotact();
        getAddress();
        $("body").addClass("sidebar-dark");
    }, []);

    const UpdateAddress = (lang) => {
        const data = {
            address: $(`#address_${lang}`).val(),
        }
        Axios.post(`/contact/address/${lang}`, data).then(res => {
            window.location.reload();
        })
    }

    return (
        <div className="main-wrapper">
            < Sidebar />
            <div className="page-wrapper">
                <nav className="navbar">
                    <a href="#0" className="sidebar-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </a>
                    <div className="navbar-content">

                        <ul className="navbar-nav">
                            <li className="nav-item" onClick={logout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <nav className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Admin</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Update Contact</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-body">
                                    <h6 className="card-title">Update Contact Info</h6>
                                    <form className="form-sample" onSubmit={(e) => {
                                        e.preventDefault();
                                        const formData = new FormData(e.target);
                                        const data = Object.fromEntries(formData);
                                        Axios.post(`/contact/update/`, data).then(() => {
                                            window.location.reload();
                                        });
                                    }}>
                                        <div className="mb-3">
                                            <label>phone <span className="text-danger">1</span></label>
                                            <input type="text" name="phone1" className="form-control" placeholder="title" defaultValue={contact.phone1} />
                                        </div>
                                        <div className="mb-3">
                                            <label>phone <span className="text-danger">2</span></label>
                                            <input type="text" name="phone2" className="form-control" placeholder="title" defaultValue={contact.phone2} />
                                        </div>
                                        <div className="mb-3">
                                            <label>Email <span className="text-danger">1</span></label>
                                            <input type="text" name="email1" className="form-control" placeholder="title" defaultValue={contact.email1} />
                                        </div>
                                        <div className="mb-3">
                                            <label>Email <span className="text-danger">2</span></label>
                                            <input type="text" name="email2" className="form-control" placeholder="title" defaultValue={contact.email2} />
                                        </div>
                                        <div className="mb-3">
                                            <button className="btn btn-primary w-25 pull-right" type="submit" >Update</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7">
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label>Address <span className="text-danger">Kurdish</span></label>
                                        <textarea id="address_krd" className="form-control" rows="4" defaultValue={address_krd.address}></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-25 pull-right" onClick={() => { UpdateAddress('krd') }} >Update</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label>Address <span className="text-danger">Arabic</span></label>
                                        <textarea id="address_ar" className="form-control" rows="4" defaultValue={address_ar.address}></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-25 pull-right" onClick={() => { UpdateAddress('ar') }} >Update</button>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="mb-3">
                                        <label>Address <span className="text-danger">English</span></label>
                                        <textarea id="address_en" className="form-control" rows="4" defaultValue={address_en.address}></textarea>
                                    </div>
                                    <div className="mb-3">
                                        <button className="btn btn-primary w-25 pull-right" onClick={() => { UpdateAddress('en') }} >Update</button>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.easy-soft.co/" target="_blank">KCU</a>.</p>
                </footer>
            </div >
        </div >
    );
}
export default UpdateContact;