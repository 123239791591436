import { Link } from "react-router-dom";
import Axios from "axios";
import { useState, useEffect } from "react";
import $ from 'jquery';

const Home = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';

    const keyword = {
        en: {
            about: 'About Us',
            intro: 'Introduction',
            activites: 'Activites',
            members: 'Members',
            latestActivities: 'Latest Activities',
            latestMembers: 'President and members of the Executive Board of the Kurdistan Contractors Union',
            readMore: 'Read More',
            title: 'Kurdistan Contractors Union High Board'
        },
        ar: {
            about: 'من نحن',
            intro: 'مقدمة',
            activites: 'الأنشطة',
            members: 'الأعضاء',
            latestActivities: 'أحدث الأنشطة',
            latestMembers: 'رئيس واعضاء المجلس التنفيذي لاتحاد مقاولي كوردستان',
            readMore: 'اقرأ المزيد',
            title: 'مجلس الاتحاد العام للمقاولين الكردستانيين'
        },
        krd: {
            about: 'دەربارە',
            intro: 'پێشەکی',
            activites: 'چالاکییەکان',
            members: 'ئەندامەکان',
            latestActivities: 'دوایین چالاکییەکان',
            latestMembers: 'سەرۆک و ئەندامانی دەستەی کارگێڕی یەکێتی بەڵێندەرانی کوردستان',
            readMore: 'زیاتر بخوێنەوە',
            title: 'یەکێتی بەلێندەرانی کوردستان، دەستەی باڵا'
        }
    }

    const [silder, setSilder] = useState([]);
    const getSilder = async () => {
        const res = await Axios.get(`/silder?lang=${lang}`);
        setSilder(res.data);
    }

    const [main, setMain] = useState({});
    const getMain = async () => {
        const res = await Axios.get(`/intro?lang=${lang}`);
        setMain(res.data[0]);
    }
    const [members, setMembers] = useState([]);
    const getMembers = async () => {
        const res = await Axios.get(`/members-limit?lang=${lang}`);
        setMembers(res.data);
    }
    const [activities, setActivities] = useState([]);
    const getActivities = async () => {
        const res = await Axios.get(`/activities-limit?lang=${lang}`);
        setActivities(res.data);
    }

    useEffect(() => {
        getSilder();
        getMain();
        getMembers();
        getActivities();
        setTimeout(() => {
            if (lang !== "en") {
                $(".blog-content").css("direction", "rtl");
                $(".about-shape").css("direction", "rtl");
                $(".shape-right").removeClass("right").addClass("left");
                $(".hero-style1").css("direction", "rtl");
                $(".line-btn").addClass("line-btn_rtl");
            } else {
                $(".blog-content").css("direction", "ltr");
                $(".about-shape").css("direction", "ltr");
                $(".shape-right").addClass("right").removeClass("left");
                $(".line-btn").removeClass("line-btn_rtl");
            }
        }, 500);
        document.title = keyword[lang].title;
    }, [])

    return (
        <div>
            <div className="as-hero-wrapper hero-1">
                <div className="hero-slider-1 as-carousel" data-fade="true" data-slide-show={1} data-md-slide-show={1}>
                    {silder.map((item, index) => (
                        <div className="as-hero-slide" key={index}>
                            <div className="as-hero-bg" data-bg-src={item.img} data-overlay="overlay2" data-opacity={9} />
                            <div className="container">
                                <div className="hero-style1">
                                    <span className="hero-subtitle" data-ani="slideinleft" data-ani-delay="0.1s">{item.sub_title}</span>
                                    <h1 className="hero-title" data-ani="slideinleft" data-ani-delay="0.3s">{item.main_title}</h1>
                                    <p className="hero-text" data-ani="slideinleft" data-ani-delay="0.6s">{item.content}</p>
                                </div>
                            </div>
                            <div className="hero-img">
                                <img src={item.img} alt="Hero Image" />
                                <button data-slick-next=".hero-slider-1" className="icon-btn">
                                    <i className="far fa-long-arrow-right" />
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="space" id="about-sec">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-xl-6">
                            <div className="img-box1"><img src={main.img} alt="About" />
                                <div className="shape"><img src="assets/img/normal/about_shape_1.svg" alt="shape" /></div>
                            </div>
                        </div>
                        <div className="col-xl-6 ps-3 ps-xl-5">
                            <div className="shape about-shape">
                                <div className="title-area mb-30">
                                    <span className="sub-title">{keyword[lang].intro} <span className="shape shape-right right"><span className="dots" /></span></span>
                                </div>
                                <p className="mt-n2 mb-4">
                                    <div dangerouslySetInnerHTML={{ __html: main.content }} />
                                </p>
                                <a href="/about" className="line-btn">{keyword[lang].readMore}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <section className="space" data-bg-src="assets/img/bg/blog_bg_1.png">
                <div className="container">
                    <div className="title-area text-center">
                        <span className="sub-title justify-content-center">
                            <span className="shape left"><span className="dots" /></span> {keyword[lang].activites}  <span className="shape right"><span className="dots" /></span>
                        </span>
                        <h3 className="sec-title">
                            {keyword[lang].latestActivities}
                        </h3>
                    </div>
                    <div className="row">
                        {activities.map((item, index) => (
                            <div className="col-md-6 col-xl-4" key={index}>
                                <div className="blog-card">
                                    <div className="blog-img">
                                        <img src={item.img} alt="blog image" />
                                    </div>
                                    <div className="blog-content">
                                        <div className="blog-meta">
                                            <Link to={`/activity/${item.id}`}><i className="fal fa-calendar-days" />{new Date(item.created).toLocaleDateString()}</Link>
                                        </div>
                                        <h3 className="blog-title">
                                            <Link to={`/activity/${item.id}`}>{item.title}</Link>
                                        </h3>
                                        <p className="blog-text">
                                            <div dangerouslySetInnerHTML={{ __html: item.content.substring(0, 100) }} />
                                        </p>
                                        <Link to={`/activity/${item.id}`} className="line-btn"> {keyword[lang].readMore}
                                            {lang === 'en' ? <i className="far fa-long-arrow-right" /> : <i className="far fa-long-arrow-left" />}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
        </div>
    );
}

export default Home;