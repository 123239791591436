import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import $ from 'jquery';


const Activites = () => {
    const lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'krd';
    const keyword = {
        en: {
            activites: 'Activites',
            latestActivities: 'Latest Activities',
            readMore: 'Read More',
        },
        ar: {
            activites: 'الأنشطة',
            latestActivities: 'أحدث الأنشطة',
            readMore: 'اقرأ المزيد',
        },
        krd: {
            activites: 'چالاکییەکان',
            latestActivities: 'دوایین چالاکییەکان',
            readMore: 'زیاتر بخوێنەوە',
        }
    }
    const [activities, setActivities] = useState([]);
    const getActivities = async () => {
        const res = await Axios.get(`/activities?lang=${lang}`);
        setActivities(res.data);
    }
    useEffect(() => {
        getActivities();
        setTimeout(() => {
            if (lang !== "en") {
                $(".blog-content").css("direction", "rtl");
                $(".line-btn").addClass("line-btn_rtl");
            }
        }, 500);
        document.title = keyword[lang].activites;

    }, [])

    return (
        <section className="space" data-bg-src="assets/img/bg/blog_bg_1.png">
            <div className="container">
                <div className="title-area text-center">
                    <span className="sub-title justify-content-center">
                        <span className="shape left"><span className="dots" /></span> {keyword[lang].activites}  <span className="shape right"><span className="dots" /></span>
                    </span>
                    <h3 className="sec-title">
                        {keyword[lang].latestActivities}
                    </h3>
                </div>
                <div className="row">
                    {activities.map((item, index) => (
                        <div className="col-md-6 col-xl-4" key={index}>
                            <div className="blog-card">
                                <div className="blog-img">
                                    <img src={item.img} alt="blog image" />
                                </div>
                                <div className="blog-content">
                                    <div className="blog-meta">
                                        <Link to={`/activity/${item.id}`}><i className="fal fa-calendar-days" />{new Date(item.created).toLocaleDateString()}</Link>
                                    </div>
                                    <h3 className="blog-title">
                                        <Link to={`/activity/${item.id}`}>{item.title}</Link>
                                    </h3>
                                    <p className="blog-text">
                                        <div dangerouslySetInnerHTML={{ __html: item.content.substring(0, 100) }} />
                                    </p>
                                    <Link to={`/activity/${item.id}`} className="line-btn"> {keyword[lang].readMore}
                                        {lang === 'en' ? <i className="far fa-long-arrow-right" /> : <i className="far fa-long-arrow-left" />}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
}

export default Activites;