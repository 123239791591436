import { useState, useEffect } from "react";
import Axios from "axios";
import $ from "jquery";
import Sidebar from "./Sidebar";
import { toast } from "react-toastify";

const UpdateSlider = () => {

    const logout = () => {
        Axios.post('/logout').then((ok) => {
            if (ok.data != 'err') {
                window.location.assign("/login")
            } else {
                window.location.reload()
            }
        })
    }

    const [slider, setSlider] = useState([]);
    const getSlider = async () => {
        const res = await Axios.get("/slider/get");
        setSlider(res.data);
    };

    const upload = (id) => {
        $(`.viewImg_${id}`).remove();
        $('<input type="file"  />').click().on('change', async (e) => {
            var formData = new FormData();
            formData.append('file', e.target.files[0]);
            await Axios.post('/upload', formData).then(res => {
                if (res.data !== "err") {
                    $(`#img_${id}`).val(res.data);
                    $(`#img${id}`).append(`<img class="viewImg_${id}" src="${res.data}" width="100px" height="100px"style="border: solid #615dfa; margin-bottom: 10px; margin-right: 10px; cursor: pointer;" />`)
                }
            })
        })
    }

    useEffect(() => {
        getSlider();
        $("body").addClass("sidebar-dark");
    }, []);

    return (
        <div className="main-wrapper">
            < Sidebar />
            <div className="page-wrapper">
                <nav className="navbar">
                    <a href="#0" className="sidebar-toggler">
                        <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="feather feather-menu"><line x1={3} y1={12} x2={21} y2={12} /><line x1={3} y1={6} x2={21} y2={6} /><line x1={3} y1={18} x2={21} y2={18} /></svg>
                    </a>
                    <div className="navbar-content">
                        <ul className="navbar-nav">
                            <li className="nav-item" onClick={logout}>
                                Logout
                            </li>
                        </ul>
                    </div>
                </nav>
                <div className="page-content">
                    <nav className="page-breadcrumb">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="#">Admin</a></li>
                            <li className="breadcrumb-item active" aria-current="page">Slider List</li>
                        </ol>
                    </nav>
                    <div className="row">
                        <div className="col-md-12 grid-margin stretch-card">
                            <div className="card">
                                <div className="card-body">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <h6 className="card-title">Sliders</h6>
                                        </div>
                                    </div>
                                    <div className="table-responsive">
                                        <table className="table table-striped table-sm dt-tbl" style={{ width: "100%" }}>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Sub Title</th>
                                                    <th>Main Title</th>
                                                    <th>Image</th>
                                                    <th>Language</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {slider.map((slider, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{slider.sub_title}</td>
                                                        <td>{slider.main_title}</td>
                                                        <td><img src={slider.img} alt="member" className="img-fluid" style={{ width: "50px", height: "50px" }} /></td>
                                                        <td>{slider.lang}</td>
                                                        <td>
                                                            <button className="btn btn-small btn-primary mx-2" data-bs-toggle="modal" data-bs-target={`#update${slider.id}`}>Edit</button>
                                                            <div className="modal fade" id={`update${slider.id}`} tabIndex={-1} aria-labelledby={`update${slider.id}`} aria-hidden="true">
                                                                <div className="modal-dialog modal-lg">
                                                                    <div className="modal-content">
                                                                        <div className="modal-header">
                                                                            <h5 className="modal-title">Update Slider </h5>
                                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="btn-close" />
                                                                        </div>
                                                                        <form className="form-sample" onSubmit={(e) => {
                                                                            e.preventDefault();
                                                                            const formData = new FormData(e.target);
                                                                            const data = Object.fromEntries(formData);
                                                                            Axios.post(`/slider/update/${slider.id}`, data).then(res => {
                                                                                if (res.data !== 'done') {
                                                                                    toast.error(res.data, {
                                                                                        position: "top-left",
                                                                                        autoClose: 3000,
                                                                                        hideProgressBar: false,
                                                                                        closeOnClick: true,
                                                                                        pauseOnHover: true,
                                                                                        draggable: true,
                                                                                        progress: undefined,
                                                                                    });
                                                                                } else {
                                                                                    e.target.reset();
                                                                                    getSlider();
                                                                                    toast.success("Slider Updated Successfully")
                                                                                    window.$(`#update${slider.id}`).modal('hide')
                                                                                }
                                                                            });
                                                                        }}>
                                                                            <div className="modal-body">
                                                                                <div className="mb-3">
                                                                                    <label>Sub Title</label>
                                                                                    <input type="text" name="sub_title" className="form-control" placeholder="title" defaultValue={slider.sub_title} />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label>Main Title</label>
                                                                                    <input type="text" name="main_title" className="form-control" placeholder="title" defaultValue={slider.main_title} />
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label>Content</label>
                                                                                    <textarea name="content" className="form-control" rows="4" defaultValue={slider.content}></textarea>
                                                                                </div>
                                                                                <div className="mb-3">
                                                                                    <label>Image</label>
                                                                                    <div className="form-control text-center" onClick={() => { upload(slider.id) }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                                                    </div>
                                                                                    <br />
                                                                                    <div align="center" id={`img${slider.id}`}>
                                                                                        {slider.img && slider.img.length > 0 ?
                                                                                            <img className={`viewImg_${slider.id}`} src={slider.img} width="100px" height="100px" style={{ border: 'solid #615dfa', marginBottom: 10, marginRight: 10, cursor: 'pointer' }} />
                                                                                            : null}
                                                                                        <input type="hidden" name="img" id={`img_${slider.id}`} value={slider.img} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="modal-footer">
                                                                                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                                                                                <button type="submit" className="btn btn-dark"> Update</button>
                                                                            </div>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <footer className="footer d-flex flex-column flex-md-row align-items-center justify-content-between px-4 py-3 border-top small">
                    <p className="text-muted mb-1 mb-md-0">Copyright © 2022 <a href="https://www.easy-soft.co/" target="_blank">KCU</a>.</p>
                </footer>
            </div >
        </div >
    );
}

export default UpdateSlider;